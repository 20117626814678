import styled from "styled-components";

export const OurClients = styled.div`
    @media (max-width: 1200px){
      padding-top: 0!important;
      margin-top: 0!important;
    }
    
    .card{
      width: 25%!important;
      height: 100%;
      padding: 0 12px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      @media (max-width: 576px){
        padding: 0 6px !important;
      }
      .card-body{
      background-color: white;
      border-radius: 24px;
        @media (max-width: 576px){
        //width: 20% !important;
        padding: 8px 18px !important;
        border-radius: 12px !important;
        
      }
      img{
        width: 150px !important;
        height: 50px !important;
        @media (max-width: 576px){
        width: 84px !important;
        height: 34px !important;
      }
      }
      }
    }
    .marquee-container{
    margin-top: 24px;
    .overlay:before, .overlay:after{
    background: transparent !important;
    }
    }
  h3{
    font-weight: 600;
    font-size: 48px;
    line-height: 55px;
    color: #2091F9;
    text-align: center;
    @media (max-width: 576px){
      font-size: 35px;
    }
  }
`