// React Imports
import React from 'react';
import {Col, Row} from "reactstrap";
import {AnimationOnScroll} from 'react-animation-on-scroll';
import {useTranslation} from "react-i18next";
// Images
// @ts-ignore
import bigant from "../../../../assets/icons/bigant.svg"
import IphoneMobile from "../../../../assets/images/bigant_mobile.png";
import IphoneLaptop from "../../../../assets/images/bigant_laptop.png";
import unicalU from "../../../../assets/images/Unical-u.png";
// Components
import BigantMiniCard from "../../../../components/cards/servicesMiniCard"
// Json data
import {BigantCard} from "../../../../data/index";
// Styles
import {BigantMobileLeft, BigantMobileRight, Bigantuz, RadialBigant} from "./style";



const Bigant = () => {
    const {t} = useTranslation()
    return (
        <Bigantuz className="mt-5 pt-5">
            <img src={unicalU} className="bigant-unicalU" alt="Unical"/>
            <img src={unicalU} className="bigant-unicalU2" alt="Unical"/>
            <img src={unicalU} className="bigant-unicalU3" alt="Unical"/>
            <img src={unicalU} className="bigant-unicalU4" alt="Unical"/>
            <Row>
                <div className="bigant-media">
                    <AnimationOnScroll
                        animateIn="animate__lightSpeedInLeft"
                        duration={0.8}
                        animateOnce={true}
                    >
                        <img src={bigant} className="w-75" alt="Bigant"/>
                    </AnimationOnScroll>
                </div>
                <Col md="5" sm="12">
                    <AnimationOnScroll
                        animateIn="animate__lightSpeedInLeft"
                        duration={0.8}
                        animateOnce={true}
                    >
                        <img src={bigant} className="w-75" alt="Bigant"/>
                    </AnimationOnScroll>
                    <AnimationOnScroll
                        animateIn="animate__bounceInLeft"
                        duration={0.8}
                        delay={200}
                        animateOnce={true}
                    >
                        <h3 className="text-white">{t("what we did")}</h3>
                    </AnimationOnScroll>

                    <Row>
                        {
                            BigantCard.map((item, index) => {
                                return <Col key={index} md="4" sm="6" className="pt-4">
                                    <BigantMiniCard img={item.img} backStyle={item.backStyle} text={`${t(item.text)}`}
                                                    animation={item.animation}/>
                                </Col>
                            })
                        }
                    </Row>

                </Col>
                <Col md="7" sm="12" className="d-flex align-items-center">
                    <div className="mt-5">
                        <div className="d-flex align-items-center mt-5 mx-lg-auto">
                            <AnimationOnScroll
                                animateIn="animate__bounceInRight"
                                duration={0.8}
                                delay={300}
                                style={{zIndex: "2"}}
                                animateOnce={true}
                            >
                                <BigantMobileLeft>
                                    <img src={IphoneMobile} className="w-100" alt="Bigant Mobile"/>
                                </BigantMobileLeft>
                            </AnimationOnScroll>
                            <AnimationOnScroll
                                animateIn="animate__bounceInRight"
                                duration={0.8}
                                style={{zIndex: "1"}}
                                animateOnce={true}
                            >
                                <BigantMobileRight>
                                    <img src={IphoneLaptop} className="w-100" alt="Bigant Laptop"/>
                                </BigantMobileRight>
                            </AnimationOnScroll>
                        </div>
                        <AnimationOnScroll
                            animateIn="animate__zoomIn"
                            delay={400}
                            duration={0.5}
                            animateOnce={true}
                        >
                            <RadialBigant className="radial"/>
                        </AnimationOnScroll>
                    </div>
                </Col>
                <Col md="5" sm="12">
                    <AnimationOnScroll
                        animateIn="animate__lightSpeedInLeft"
                        duration={0.8}
                        animateOnce={true}
                    >
                        <img src={bigant} className="w-75" alt="Bigant"/>
                    </AnimationOnScroll>
                    <AnimationOnScroll
                        animateIn="animate__bounceInLeft"
                        duration={0.8}
                        delay={200}
                        animateOnce={true}
                    >
                        <h3 className="text-white">{t("what we did")}</h3>
                    </AnimationOnScroll>

                    <Row>
                        {
                            BigantCard.map((item, index) => {
                                return <Col key={index} md="4" sm="6" className="pt-4">
                                    <BigantMiniCard img={item.img} backStyle={item.backStyle} text={`${t(item.text)}`}
                                                    animation={item.animation}/>
                                </Col>
                            })
                        }
                    </Row>

                </Col>
            </Row>
        </Bigantuz>
    );
};

export default Bigant;