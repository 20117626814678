import styled, {keyframes} from "styled-components";
import {Container} from "reactstrap";
import {ImageTypes} from "./type";

export const BuildSection = styled(Container)`
  .row{
    .col-md-6:first-child{
      @media (max-width: 576px){
        display: block;
      }
      @media (min-width: 576px){
        display: none;
      }
    }
    .col-md-6:nth-child(2){
      @media (max-width: 992px){
        min-width: 100%;
      }
    }
    .col-md-6:last-child{
      @media (max-width: 576px){
        display: none !important;
      }
      @media (min-width: 576px){
        display: block !important;
      }
       @media (max-width: 992px){
        min-width: 100%;
      }
    }
  }
`

export const Title = styled.h3`
  font-weight: 600;
  font-size: 60px;
  color: #2091F9;
  @media (max-width: 576px){
    font-size: 40px;
  }
  @media  (min-width: 992px) and (max-width: 1200px){
   font-size: 50px;
  }
`

export const Text = styled.p`
  width: 80%;
  font-weight: normal;
  line-height: 40px;
  font-size: 20px;
  @media (max-width: 576px){
    margin-top: 10px !important;
    width: 100%;
    text-align: start !important;
  }
  @media  (min-width: 992px) and (max-width: 1200px){
   width: 90%;
  }
   @media (max-width: 992px){
        text-align: start;
        width: 100%;
   }
`

export const LearnMore = styled.div`
   transform: rotate(-90deg);
   position: absolute;
   left: -70px;
   bottom: -50px;
   cursor: pointer !important;
   z-index: 9;
   @media (max-width: 992px){
    display: none;
  }
  @media (min-width: 1400px){
    bottom: 0;
  }
  @media (min-width: 1200px){
    bottom: -120px;
  }
  @media (max-width: 1200px){
    bottom: -135px;
  }
  //@media (min-width: 576px){
  //  display: block;
  //}
  
  .mouse{
    width: 40px;
    height: 28px;
    border: 2px solid #2091F9;
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.25)) drop-shadow(-4px 0px 4px rgba(255, 255, 255, 0.25));
    border-radius: 16px;
    padding: 0 5px;
    
    .mouse-line{
      width: 8px;
      height: 0;
      border: 1px solid #2091F9;
      border-radius: 4px !important;
      background-color: #2091F9;
      animation-name: ${() => Mouse};
      animation-duration: 3s;
      animation-iteration-count: infinite;
      //animation-timing-function: linear;
      //transition: all 0.3s linear;
    }
  }
  p{
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #2091F9;
  }
`

const Mouse = keyframes`
  0%{transform: translateX(0)}
  40%{transform: translateX(-18px)}
  50%{transform: translateX(-18px)}
  100%{transform: translateX(0)}
`

const Chewron = keyframes`
  0%{transform: translateY(0)}
  40%{transform: translateY(18px)}
  50%{transform: translateY(18px)}
  100%{transform: translateY(0)}
`

export const ArrowBottom = styled.div`
  mask-image: url(${ (props: ImageTypes) => props.chevronBottom}) !important;
    mask-size: 100%;
    mask-repeat: no-repeat;
    -webkit-mask-image: url(${ (props: ImageTypes) => props.chevronBottom}) !important;
    -webkit-mask-size: 100%;
    -webkit-mask-repeat: no-repeat;
    width: 25px;
    height: 25px;
    background: #2091F9 !important;
    animation-name: ${() => Chewron};
    animation-duration: 3s;
    animation-iteration-count: infinite;
`