// React Imports
import React from 'react';

// Components
import HomeContainer from "../modules/Home/Container/container"

const HomePage = () => {
    return <HomeContainer/>
};

export default HomePage;