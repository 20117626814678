// React Imports
import React from 'react';
import {
    Card,
    CardBody,
    Col,
    Row
} from "reactstrap";



// Types
interface Type {
    title: string,
    text: string,
    img: any,
    animation?: any
}

const CardServices = (props: Type) => {
    return (
        <Card>
            <CardBody>
                <Row>
                    <Col md="6" sm="12">
                        <img src={props.img} className="w-100" alt="Development of landing pages"/>
                    </Col>
                    <Col md="6" sm="12" className="d-flex">
                        <div>
                            <h3 className="text-white">{props.title}</h3>
                            <p className="text-white mt-3">{props.text}</p>
                        </div>
                    </Col>
                    <Col md="6" sm="12">
                        <img src={props.img} className="w-100" alt="Development of landing pages"/>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default CardServices;