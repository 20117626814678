// React Imports
import React from 'react';
import {
    Col,
    Row
} from "reactstrap";
import { AnimationOnScroll } from 'react-animation-on-scroll';

// Styles
import  {
    Comfort,
    IPhoneLeft,
    IPhoneMiddle,
    IPhoneRight,
    Radial,
} from "./style";

// Components
import ComfortoilMiniCard from "../../../../components/cards/servicesMiniCard"

// Json data
import {ComfortOilCard} from "../../../../data/index";

// Images
import IphoneLeft from "../../../../assets/images/iPhone12Pro.png"
import IphoneMiddle from "../../../../assets/images/iPhone12ProMiddle.png"
import IphoneRight from "../../../../assets/images/iPhone12ProRight.png"
import unicalU from "../../../../assets/images/Unical-u.png"
import {useTranslation} from "react-i18next";

const Comfortoil = () => {
    const {t} = useTranslation();
    return (
        <Comfort id="comfortOil">
            <img src={unicalU} className="comfort-unicalU" alt="Unical"/>
            <img src={unicalU} className="comfort-unicalU2" alt="Unical"/>
            <Row className="pt-5 mt-5">
                <Col md="6" sm="12">
                    <AnimationOnScroll
                        animateIn="animate__fadeInRightBig"
                        duration={0.8}
                        animateOnce={true}
                    >
                        <h1>Comfort oil</h1>
                    </AnimationOnScroll>
                    <AnimationOnScroll
                        animateIn="animate__fadeInRightBig"
                        delay={200}
                        duration={0.8}
                        animateOnce={true}
                    >
                        <p className="text-white mt-3 mb-4">{t("comfortOilText")}</p>
                    </AnimationOnScroll>
                </Col>
                <Col md="6" sm="12" className="px-5">
                    <div className="d-flex align-items-center">
                        <AnimationOnScroll
                            animateIn="animate__rollIn"
                            delay={300}
                            duration={0.7}
                            style={{zIndex: "2"}}
                            animateOnce={true}
                        >
                            <IPhoneLeft>
                                <img src={IphoneLeft} className="w-100" alt="Iphone 12 Pro"/>
                            </IPhoneLeft>
                        </AnimationOnScroll>
                        <AnimationOnScroll
                            animateIn="animate__bounceInDown"
                            delay={600}
                            duration={0.8}
                            style={{zIndex: "2"}}
                            animateOnce={true}
                        >
                            <IPhoneMiddle>
                                <img src={IphoneMiddle} className="w-100" alt="Iphone 12 Pro"/>
                            </IPhoneMiddle>
                        </AnimationOnScroll>
                        <AnimationOnScroll
                            animateIn="animate__rollIn"
                            duration={0.7}
                            style={{zIndex: "1"}}
                            animateOnce={true}
                        >
                            <IPhoneRight>
                                <img src={IphoneRight} className="w-100" alt="Iphone 12 Pro"/>
                            </IPhoneRight>
                        </AnimationOnScroll>
                    </div>
                    <AnimationOnScroll
                        animateIn="animate__zoomIn"
                        delay={500}
                        duration={0.5}
                        animateOnce={true}
                    >
                        <Radial className="w-100">

                        </Radial>
                    </AnimationOnScroll>
                </Col>
                <Col md="6" sm="12">
                        <AnimationOnScroll
                            animateIn="animate__fadeInRightBig"
                            duration={0.8}
                            animateOnce={true}
                        >
                            <h1>Comfort oil</h1>
                        </AnimationOnScroll>
                        <AnimationOnScroll
                            animateIn="animate__fadeInRightBig"
                            delay={200}
                            duration={0.8}
                            animateOnce={true}
                        >
                            <p className="text-white mt-3 mb-4">{t("comfortOilText")}</p>
                        </AnimationOnScroll>

                    <Row>
                        <Col md="10">
                            <Row>
                                <AnimationOnScroll
                                    animateIn="animate__fadeInRightBig"
                                    delay={400}
                                    duration={0.8}
                                    animateOnce={true}
                                >
                                    <h3 className="text-white">{t("what we did")}</h3>
                                </AnimationOnScroll>
                                {
                                    ComfortOilCard.map((item, index) => {
                                        return  <Col key={index} md="4" sm="4" className="pt-4">
                                            <ComfortoilMiniCard img={item.img} backStyle={item.backStyle} text={`${t(item.text)}`} animation={item.animation}/>

                                        </Col>
                                    })
                                }
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Comfort>
    );
};

export default Comfortoil;