// React Imports
import React from 'react';
import {Col, Container, Row} from "reactstrap";
// Styles
import {FooterAll, TextContact} from "./style";
// Images
import unicalLogo from "../../../../assets/images/unical.png"
import map from "../../../../assets/icons/fi_map.svg"
import phone from "../../../../assets/icons/fi_phone-android.svg"
import email from "../../../../assets/icons/fi_mail.svg"
import telegram from "../../../../assets/icons/telegram.svg"
import facebook from "../../../../assets/icons/facebook.svg"
import linkedin from "../../../../assets/icons/linkedin.svg"

import {useTranslation} from "react-i18next";

const Footer = () => {
    const {t} = useTranslation();
    return (
        <FooterAll optimal={unicalLogo}>
            <Container>
                <Row>
                    <Col md="3" sm="12">
                        <ul>
                            <li><a href="#home">{t("home")}</a></li>
                            <li><a href="#services">{t("services")}</a></li>
                            <li><a href="#comfortOil">{t("portfolio")}</a></li>
                            <li><a href="#clients">{t("clients")}</a></li>
                            <li><a href="#contact">{t("contact")}</a></li>
                        </ul>
                    </Col>
                    <Col md="3" sm="12">
                        <div className="d-flex align-items-center justify-content-center">
                            <ul className="nav flex-column">
                                <li className="nav-item"><a href="#home" className="text-center">{t("home")}</a></li>
                                <li className="nav-item"><a href="#services" className="text-center">{t("services")}</a>
                                </li>
                                <li className="nav-item"><a href="#comfortOil"
                                                            className="text-center">{t("portfolio")}</a></li>
                                <li className="nav-item"><a href="#clients" className="text-center">{t("clients")}</a>
                                </li>
                                <li className="nav-item"><a href="#contact" className="text-center">{t("contact")}</a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md="5" sm="12">
                        <div className="d-flex align-items-center" style={{cursor: "pointer"}} onClick={() => {
                            window.open("https://maps.google.com?q=" + 41.355537330890826 + "," + 69.28798409762611)
                        }}>
                            <img src={map} alt="Maps"/>
                            <p className="mb-0 w-75 mx-2">Toshkent, Amir Temur ko'chasi 129B</p>
                        </div>
                        <a href="tel:+999781993700" className="d-flex align-items-center my-3 text-decoration-none">
                            <img src={phone} alt="Phone android"/>
                            <p className="mb-0 w-75 mx-2">(97) 199-3700</p>
                        </a>
                        <a href="mailto:unicalsolutions@gmail.com"
                           className="d-flex align-items-center text-decoration-none">
                            <img src={email} alt="email"/>
                            <p className="mb-0 w-75 mx-2">unicalsolutions@gmail.com</p>
                        </a>
                    </Col>
                    <Col md="12" sm="12" style={{cursor: "pointer"}} onClick={() => {
                        window.open("https://maps.google.com?q=" + 41.355537330890826 + "," + 69.28798409762611)
                    }}>
                        <div className="d-flex justify-content-center">
                            <img src={map} width="25" height="31" alt="Maps"/>
                        </div>
                        <TextContact className="text-center text-white">Toshkent, Amir Temur ko'chasi 129B</TextContact>
                    </Col>
                    <Col md="12" sm="12">
                        <a href="tel:+999781993700" className="text-decoration-none">
                            <div className="d-flex justify-content-center">
                                <img src={phone} width="25" height="31" alt="Maps"/>
                            </div>
                            <TextContact className="text-center text-white">(97) 199-3700</TextContact>
                        </a>
                    </Col>
                    <Col md="4" sm="12">
                        <p>{t("social media")}</p>
                        <div className="d-flex align-items-center">
                            <a href="https://t.me/kahero" target="_blank" rel="noreferrer"
                               className="text-decoration-none"><img src={telegram} width="40" height="40"
                                                                     alt="Telegram"/></a>
                            <a href="http://www.facebook.com/Unical-Solutions-104111662133943" target="_blank"
                               rel="noreferrer" className="text-decoration-none mx-4"><img src={facebook} width="40"
                                                                                           height="40" alt="Facebook"/></a>
                            <a href="https://www.linkedin.com/company/unical-solutions" target="_blank" rel="noreferrer"
                               className="text-decoration-none"><img src={linkedin} width="40" height="40"
                                                                     alt="Linkedin"/></a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </FooterAll>
    );
};

export default Footer;