// React Imports
import React, {useContext} from 'react';
import {
    Col,
    Row
} from "reactstrap";

// Images
import unicalU from "../../../../assets/images/Unical-u.png"

// Styles
import {Service} from "./style"

// Components
import ServicesCard from "../../../../components/cards/servicesCard"

// Json data
import {ServiceCard} from "../../../../data/index";
import {useTranslation} from "react-i18next";

import {SystemContext} from "../../../../routes";



const Services = () => {
    const {t} = useTranslation();
    const {scroll} = useContext(SystemContext)
    return (
        <Service>
            <div id="services">
                <h1
                    data-aos="fade-down"
                    data-aos-offset="400"
                    data-aos-easing="ease-out-quart"
                    data-aos-duration="800"
                    data-aos-once="true"
                    className="text-center"
                >{t("our services")}</h1>
            </div>
            <img src={unicalU} className="service-unicalU" alt="Unical"/>
            <img src={unicalU} className="service-unicalU2" alt="Unical"/>
            <Row className="pt-5">
                {
                    ServiceCard.map((item, index) => {
                        return <Col
                                    data-aos={scroll ? item.animation?.dataAos : ""}
                                     data-aos-offset={scroll ? item.animation?.dataAosOffset : ""}
                                     data-aos-easing={scroll ? item.animation?.dataAosEasing : ""}
                                     data-aos-duration={scroll ? item.animation?.dataAosDuration : ""}
                                     data-aos-once={scroll ? item.animation?.dataAosOnce : true}
                                    key={index}
                                    md="6"
                                    sm="12"
                                    className="pt-4"
                        >
                            <ServicesCard title={`${t(item.title)}`} text={`${t(item.text)}`}  img={item.img}/>
                        </Col>
                    })
                }

            </Row>
        </Service>
    );
};

export default Services;