
import styled from "styled-components";


interface SType {
    click: boolean
}


export const Section = styled.section`
position: relative;
overflow: hidden;
//display: none;
transition: all 1s ease-in-out !important;
.animationsSection{
transition: all 1s ease-in-out !important;
  display: none;
}
.sectionAnimation{
    transition: all 1s ease-in-out !important;
  display: block;
}
  .medianavs{
    position: absolute;
    background: #fff;
    z-index: 1000;
    top: 0;
    width: 100% !important;
    right: ${(props: SType) => props.click ? "0" : "-150%"}; 
    height: 100vh !important;
    display: block !important;
    padding: 20px;
    transition: all 0.5s ease-in-out;
    //@media (max-width: 992px){
    //  display: ;
    //}
    
    .close{
      display: block;
      img{
      text-align: end;
        margin-left: auto !important;
      }
    }
    .navbar-nav{
      width: 100% !important;
      margin-top: 40px;
        li{
         padding: 0 16px;
          a{
          text-align: center;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 28px;
           color: #000 !important;
          &:before{
            height: 0 !important;
          }
          }
          &:last-child{
          margin-left: auto;
          margin-right: auto;
     .nav-link{
     position: relative;  
     cursor: pointer;
       font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 28px;
           color: #000 !important;
      &:before{
      content: "";
      width: 0 !important;
      height: 0 !important;
    }

    .language{
      width: 100px;
      //height: 100px;
      padding: 10px 0;
      background-color:  #2091F9;
      position:absolute;
      top: 40px;
      left: 0;
      transition: all 0.3s ease-in-out;
      display: none;
      border-radius: 12px;
      
      .item{
      padding: 10px;
      transition: all 0.3s ease-in-out;
      &:hover{
        background-color: white;
        p{
        color: #000;
        }
      }
        img{
          width: 25px;
          height: 25px;
          border-radius: 50%;
        }
        p{
          color: #fff;
          transition: all 0.3s ease-in-out;
        }
       
      }
      
    }
    &:hover{
    //color: rgba(255, 255, 255, 0.8) !important;
      .language{
        display: block;
      }
    }
     }
    
   }
        }
    }
    .phone-number{
      margin-left: auto;
     
      @media (max-width: 992px){
     display: block;
      margin-top: 50px !important;
        
      a{
        justify-content: center;
       
      }
        p{
          color: black !important;
        }
      }
  }
  }
  
`;

export const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`
