import styled from "styled-components";
import {Container} from "reactstrap";

export const Bigantuz = styled(Container)`
position: relative;
@media (max-width: 576px){
  padding-top: 0 !important;
  margin-top: 0 !important;
}
.bigant-unicalU{
position: absolute;
width: 210px;
height: 250px;
top: -250px;
transform: rotate(70deg);
@media (max-width: 576px){
  width: 50px;
  height: 60px;
  top: -50px;
  right: 50px;
}
}
.bigant-unicalU2{
position: absolute;
width: 210px;
height: 250px;
top: 100px;
right: 5%;
transform: rotate(-120deg);
@media (max-width: 1200px){
  right: 10%;
}
@media (max-width: 576px){
  display: none;
}
}
.bigant-unicalU3{
position: absolute;
width: 210px;
height: 250px;
bottom: -50px;
transform: rotate(50deg);
@media (max-width: 576px){
  width: 50px;
  height: 60px;
  bottom: -100px;
  left: 20px;
  transform: rotate(80deg);

}
}
.bigant-unicalU4{
@media (max-width: 576px){
display: block;
position: absolute;
transform: rotate(15deg);
  width: 50px;
  height: 60px;
  bottom: -80px;
  right: 50px;
}
@media (min-width: 576px){
  display: none;
}
}
.card{
        background-color: #23265C;
        height: 100%;
        cursor: pointer;
      }
h3{
        font-weight: 500;
        font-size: 32px;
        line-height: 55px;
        margin-top: 100px;
      }
 .row{
  .bigant-media{
    @media (max-width: 1200px){
      display: block;
    }
    @media (max-width: 1200px) and (min-width: 767px){
      width: 50%;
    }
    @media (max-width: 767px) and (min-width: 576px){
      width: 70%;
    }
    @media (min-width: 1200px){
      display: none;
    }
  }  
  .col-md-5:nth-child(2){
    @media (max-width: 1200px){
      display: none !important;
    }
    @media (min-width: 1200px){
      display: block !important;
    }
    @media (max-width: 1400px) and (min-width: 1200px){
            .card-body{
              padding: 10px;
              
            }
            span{
                font-size: 13px !important;
              }
          }
    .card{
      transition: all 0.3s ease-in-out;
        &:hover{
          transform: translateY(-15px);
          box-shadow: 0 0 20px #23265C;
         }
    }
  } 
  .col-md-5:last-child{
    @media (max-width: 1200px){
      display: block !important;
      transform: translateY(-150px);
    }
    @media (max-width: 1200px) and (min-width: 576px){
      min-width: 100%;
      transform: translateY(-200px);
    }
    @media (min-width: 1200px){
      display: none !important;
    }
    .w-75{
      @media (max-width: 1200px){
      display: none !important;
    }
    }
    .row{
      .col-md-4{
        max-width: 33% !important;
        .card{
        height: 100px !important;
        transition: all 0.3s ease-in-out;
        &:hover{
          transform: translateY(-15px);
          box-shadow: 0 0 20px #23265C;
         }
      
        @media (max-width: 1200px) and (min-width: 576px){
          height: 140px !important;
        }
          .card-body{
            padding: 10px 0 !important;
          }
          span{
            font-size: 12px;
          }
        }
      }
     }
  }   
  .col-md-7{
    @media (max-width: 1200px){
      min-width: 100%;
    }
    .mt-5{
    @media (max-width: 1200px){
        margin-top: 0 !important;
    }
    @media (max-width: 767px){
      margin-top: 50px !important;
    }
    @media (max-width: 576px){
      margin-top: 20px !important;
    }
      
      }
  }
 }
`
export const RadialBigant = styled.div`
    width: 90%;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    background: radial-gradient(50% 50% at 50% 50%, rgba(196, 196, 196, 0.5) 0%, rgba(196, 196, 196, 0) 100%);
    transform: translateY(-140px) !important;
    @media (max-width: 767px){
      width: 90%;
      height: 200px;
    }
`
export const BigantMobileLeft = styled.div`
  transform: translate(70px, 100px);
  z-index: 2;
  @media (max-width: 767px){
    transform: translate(40px, 20%);
    img{
      //width: 64px !important;
      //height: 128px !important;
    }
  }
`
export const BigantMobileRight = styled.div`
    transform: translate(-50px, 30px);
    z-index: 1;
    @media (max-width: 767px){
     //transform: translateX(0);
      transform: translate(-30px, 0);
    img{
      //display: none;
      //width: 350px !important;
      //height: 200px !important;
    }
  }
`