import styled from "styled-components";
import {Container} from "reactstrap";

export const ContactUs = styled(Container)`
  h3{
   
    font-weight: 600;
    font-size: 48px;
    line-height: 55px;
    color: #2091F9;
    text-align: center;
    @media (max-width: 576px){
      font-size: 35px;
    }
 
  }
  form{
  .card{
    background-color: #23265C;
    box-shadow: 0px 13px 19px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
    .card-body{
      padding: 50px;
      @media (max-width: 576px){
        padding: 30px;
      }
      @media (min-width: 992px) and (max-width: 1200px){
        padding: 30px;
      }
      h2{
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #fff;
    text-align: center;
    }
    input, textarea, button{
      margin-top: 30px;
      display: block;
      margin-left: auto;
    }
    input{
      border-radius: 25px;
      border: none;
      padding: 10px;
      color: #000;
      ::placeholder{
      color: #000;
      }
    }
    textarea{
    border-radius: 12px;
    border: none;
      color: #000;
      ::placeholder{
      color: #000;
      }
    }
    button{
    background: #2091F9;
    padding: 10px 25px;
    border-radius: 35px;
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
    }
    }
  }
  input, textarea{
    ::placeholder{
      font-weight: 300 !important;
      color: #171836 !important;
    }
  }
  }
  .row:nth-child(2){
    @media (max-width: 576px){
      display: block !important;
    }
    @media (min-width: 576px){
      display: none !important;
    }
  }
  .row:last-child{
    .col-md-8{
      @media (max-width: 576px){
        display: none;
      }
      @media (max-width: 992px){
        min-width: 100%;
      }
      .row{
        .col-md-4{
          min-width: 33%;
        }
      }
    }
    .col-md-4{
      @media (max-width: 992px){
        min-width: 100%;
      }
    }
  }
`;

export const TextContact = styled.p`
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    margin-top: 20px;
`;