// React Imports
import React from 'react';
import {
    Card,
    CardBody
} from "reactstrap";

//Types
import { ContactTypes } from "../../modules/Home/Types/types";
import {useTranslation} from "react-i18next";

const Form = (props: ContactTypes) => {
    const {t} = useTranslation();

    const changeInput = (e: any) => {
        if (e.target.name === "Name"){
            props.setNameInput(e.target.value);
        }if (e.target.name === "Number"){
            props.setNumberInput(e.target.value);
        }if (e.target.name === "Message"){
            props.setMessageInput(e.target.value);
        }
    }

    return (
        <form onSubmit={props.handleClick}>
            <Card>
                <CardBody>
                    <h2>{t("contact us")}</h2>
                    <input onChange={changeInput} value={props.nameInput} name="Name" required={true} type="text" className="form-control" placeholder={t("your name")}/>
                    <input onChange={changeInput} value={props.numberInput} name="Number" required={true} type="text" className="form-control" placeholder={t("your number")}/>
                    <textarea onChange={changeInput} value={props.messageInput} name="Message" required={true} className="form-control" rows={10} placeholder={t("your message")}/>
                    <button type="submit" className="btn btn-primary">{t("send")}</button>
                </CardBody>
            </Card>
        </form>
    );
};

export default Form;