// language
import {getText} from "../services/locales/index";

// Images
import serviceLandingPage from "..//assets/images/service-landing-page.png"
import serviceStartup from "../assets/images/service-startup.png"
import serviceLandingPage2 from "../assets/images/service-landing-page2.png"
import serviceConsulting from "../assets/images/service-consulting.png"
import serviceMobileApplications from "../assets/images/service-mobile-applications.png"
import serviceErp from "../assets/images/service-erp.png"

import Global from "../assets/icons/fi_globe.svg"
import AdminPanel from "../assets/icons/fi_admin-panel.svg"
import Ios from "../assets/icons/fi_apple.svg"
import android from "../assets/icons/fi_android.svg"
import webDesign from "../assets/icons/fi_monitor.svg"
import mobileDesign from "../assets/icons/fi_smartphone.svg"
import Bot from "../assets/icons/fi_bot.svg"

// Styles
import {
    WebSite,
    Admin,
    Apple,
    Android,
    WebDesign,
    MobileDesign
} from "../modules/Home/Components/comfortOil/style"

// import {useTranslation} from "react-i18next";
// const {t} = useTranslation()



export const ServiceCard = [
    {
        title: ("services card one title"),
        text: ("services card one text"),
        img: serviceLandingPage,
        animation: {
            dataAos: "fade-up-right",
            dataAosOffset: "400",
            dataAosEasing: "ease-out-quart",
            dataAosDuration: "800",
            dataAosOnce: true
        }
    },
    {
        title: ("services card two title"),
        text: ("services card two text"),
        img: serviceLandingPage2,
        animation: {
            dataAos: "fade-up-left",
            dataAosOffset: "400",
            dataAosEasing: "ease-out-quart",
            dataAosDuration: "800",
            dataAosOnce: true
        }
    },
    {
        title: ("services card three title"),
        text: ("services card three text"),
        img: serviceConsulting,
        animation: {
            dataAos: "fade-right",
            dataAosOffset: "300",
            dataAosEasing: "ease-out-quad",
            dataAosDuration: "800",
            dataAosOnce: true
        }
    },
    {
        title: ("services card four title"),
        text: ("services card four text"),
        img: serviceMobileApplications,
        animation: {
            dataAos: "fade-left",
            dataAosOffset: "300",
            dataAosEasing: "ease-out-quad",
            dataAosDuration: "800",
            dataAosOnce: true
        }
    },
    {
        title: `${("services card five title")}`,
        text: ("services card five text"),
        img: serviceStartup,
        animation: {
            dataAos: "fade-up-right",
            dataAosOffset: "300",
            dataAosEasing: "ease-in-sine",
            dataAosDuration: "800",
            dataAosOnce: true
        }
    },
    {
        title: ("services card six title"),
        text: ("services card six text"),
        img: serviceErp,
        animation: {
            dataAos: "fade-up-left",
            dataAosOffset: "300",
            dataAosEasing: "ease-in-sine",
            dataAosDuration: "800",
            dataAosOnce: true
        }
    }
]

export const ComfortOilCard = [
    {
        img: Global,
        backStyle: WebSite,
        text: ("web site"),
        animation: {
            animateIn:"animate__fadeInRightBig",
            duration:0.6,
            delay:100,
            animateOnce:true,
        }
    },
    {
        img: AdminPanel,
        backStyle: Admin,
        text: ("admin panel"),
        animation: {
            animateIn:"animate__fadeInRightBig",
            duration:0.6,
            delay:200,
            animateOnce:true
        }
    },
    {
        img: Ios,
        backStyle: Apple,
        text: ("ios"),
        animation: {
            animateIn:"animate__fadeInRightBig",
            duration:0.6,
            delay:300,
            animateOnce:true
        }
    },
    {
        img: android,
        backStyle: Android,
        text: ("android"),
        animation: {
            animateIn:"animate__fadeInRightBig",
            duration:0.6,
            delay:300,
            animateOnce:true
        }
    },
    {
        img: webDesign,
        backStyle: WebDesign,
        text: ("web design"),
        animation: {
            animateIn:"animate__fadeInRightBig",
            duration:0.6,
            delay:500,
            animateOnce:true
        }
    },
    {
        img: mobileDesign,
        backStyle: MobileDesign,
        text: ("mobil design"),
        animation: {
            animateIn:"animate__fadeInRightBig",
            duration:0.6,
            delay:600,
            animateOnce:true
        }
    }
]

export const BigantCard = [
    {
        img: Global,
        backStyle: WebSite,
        text: ("web site"),
        animation: {
            animateIn:"animate__fadeInLeftBig",
            duration:0.6,
            animateOnce:true,
        }
    },
    {
        img: AdminPanel,
        backStyle: Admin,
        text: ("admin panel"),
        animation: {
            animateIn:"animate__fadeInLeftBig",
            duration:0.6,
            delay:200,
            animateOnce:true
        }
    },
    {
        img: Bot,
        backStyle: Apple,
        text: ("bot"),
        animation: {
            animateIn:"animate__fadeInLeftBig",
            duration:0.6,
            delay:300,
            animateOnce:true
        }
    },
    {
        img: webDesign,
        backStyle: WebDesign,
        text: getText("web design"),
        animation: {
            animateIn:"animate__fadeInLeftBig",
            duration:0.6,
            delay:400,
            animateOnce:true
        }
    },
    {
        img: mobileDesign,
        backStyle: MobileDesign,
        text: ("mobil design"),
        animation: {
            animateIn:"animate__fadeInLeftBig",
            duration:0.6,
            delay:500,
            animateOnce:true
        }
    }
]