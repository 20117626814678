import styled from "styled-components";
import {ImageTypes} from "./type";

export const FooterAll = styled.div`
    margin-top: 150px;
    background: #121212;  
    @media (max-width: 992px){
      margin-top: 20px;
    }
    .container{
      padding: 45px 0;
      background-image: url(${ (props: ImageTypes) => props.optimal});
      background-size: 60%;
      background-repeat: no-repeat;
      background-position: center center;
      @media (max-width: 576px){
        background-image: none  !important;
      }
      .row{
      .col-md-3:first-child{
        @media (max-width: 576px){
          display: none;
          
        }
        @media (max-width: 767px){
          max-width: 30%;
        }
      }
      .col-md-3:nth-child(2){
        @media (max-width: 576px){
          display: block;
          padding-bottom: 20px;
          li{
            margin-left: auto !important;
            margin-right: auto !important;
          }
          a{
            text-align: center !important;
            margin-left: auto !important;
            margin-right: auto !important;
          }
        }
        @media (min-width: 576px){
          display: none;
        }
      }
      .col-md-4{
        @media (max-width: 767px){
         
          p, a{
            text-align: center !important;
            color: #fff !important;
            
          }
          .d-flex{
               justify-content: center;

          }
        }        
       }
      .col-md-5{
        @media (min-width: 576px){
          display: block;
        }
        @media (max-width: 576px){
          display: none;
        }
        @media (max-width: 767px){
          max-width: 68%;
        }
       }
       .col-md-12{
          @media (max-width: 576px){
            display: block !important;
          }
          @media (min-width: 576px){
            display: none !important;
          }
       }
     
      }
    }

ul{
    li{
        margin-top: 10px;
        color: #fff;
        cursor: pointer;
        transition: all 0.1s linear;
        &:hover{
          color: #2091F9;
          a{
            color: #2091F9;
            
          }
        }
    }
}
p, a{
    font-size: 18px;
    line-height: 28px;
    color: #fff;
    transition: all 0.1s linear;
    text-decoration: none;
}
`
export const TextContact = styled.p`
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    margin-top: 20px;
`