// React Imports

// Project pages
import Home from "./pages/home";
interface IRoute {
    private: boolean,
    component?: any,
    path: string,
    exact?: boolean
}

export const routes: Array<IRoute>=[
    {
        private:false,
        component:Home,
        path:'/'
    }
]