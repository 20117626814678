import styled from "styled-components";
import {Container} from "reactstrap";
import {ImageTypes} from "./type";

export const Navigationbar = styled(Container)` 
  
 nav{
 .medianav{
 @media (max-width: 992px){
  display: none !important;
 }
 .close{
  @media (min-width: 992px){
    display: none;
  }
 }
  ul{
   li{
    padding: 0 20px;
    @media (max-width: 1200px){
      padding: 0 6px;
    }
    .nav-link{
    color: rgba(255, 255, 255, 0.8);
    position: relative;
    &:before{
        content: "";
        width: 0;
        height: 5px;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: -12px;
        background: #2091F9;
        border: 0px solid #2091F9;
        box-sizing: border-box;
        border-radius: 0px 0px 8px 8px;
        transition: all 0.2s linear;
   }
  }
    &:last-child{
     .nav-link{
     position: relative;  
     cursor: pointer;
      &:before{
      content: "";
      width: 0 !important;
      height: 0 !important;
    }

    .language{
      width: 100px;
      //height: 100px;
      padding: 10px 0;
      background-color:  #2091F9;
      position:absolute;
      top: 40px;
      left: 0;
      transition: all 0.3s ease-in-out;
      display: none;
      border-radius: 12px;
      
      .item{
      padding: 10px;
      transition: all 0.3s ease-in-out;
      &:hover{
        background-color: white;
        p{
        color: #000;
        }
      }
        img{
          width: 25px;
          height: 25px;
          border-radius: 50%;
        }
        p{
          color: #fff;
          transition: all 0.3s ease-in-out;
        }
       
      }
      
    }
    &:hover{
    color: rgba(255, 255, 255, 0.8) !important;
      .language{
        display: block;
      }
    }
     }
    
   }
    &:hover{
        .nav-link:before{
        width: 100%;
    }
    .nav-link{
      color: #2091F9 !important;
    }
   }
  }
  }
 }
 .phone-number{
      margin-left: auto;
     
      @media (max-width: 992px){
     display: none;
      margin-top: 50px !important;
      
      a{
        justify-content: center;
       
      }
        p{
          color: black !important;
        }
      }
  }
  .burger{
      @media (max-width: 992px){
        display: block !important;
      }
      @media (min-width: 992px){
        display: none !important;
      }
  }
 }
 
`;
export const Phone = styled.div`
    mask-image: url(${(props: ImageTypes) => props.phone}) !important;
    mask-size: 100%;
    mask-repeat: no-repeat;
    -webkit-mask-image: url(${(props: ImageTypes) => props.phone}) !important;
    -webkit-mask-size: 100%;
    -webkit-mask-repeat: no-repeat;
    width: 25px;
    height: 25px;
    background: ${(props: ImageTypes) => props.back} !important;
    @media (max-width: 992px){
      background: black !important;
    }
`;