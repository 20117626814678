
import { uz } from "./uz"
import { ru } from "./ru"
import { en } from "./en"

export const getLanguage = () => {
    return localStorage.getItem("lang")
};

export const getText = (word) => {
    return getLanguage() === "uz" ?
        uz[word] :
        getLanguage() === "ru" ?
            ru[word] :
            en[word]
};