// React Imports
import React, {useContext} from 'react';
import {Col, Row} from "reactstrap";
import Typist from 'react-typist';
// Images
import pencil from "../../../../assets/images/pencil-list.png"
import chevronBottom from "../../../../assets/icons/fi_chevrons-bottom.svg"
// Styles
import {ArrowBottom, BuildSection, LearnMore, Text, Title} from "./style";
import "react-typist/dist/Typist.css"
import {useTranslation} from "react-i18next";
import {SystemContext} from "../../../../routes";

const PencilAnimation = () => {
    const {language, setScroll} = useContext(SystemContext);
    const {t} = useTranslation();
    return (
        <BuildSection>
            <Row className="pt-5 mt-5">
                <Col md="6" sm="12">
                    <img src={pencil} className="w-100" alt="pencilListImage"/>
                </Col>
                <Col md="6" sm="12" className="position-relative">
                    <Title>
                        {
                            language === "uz" ?
                                <span>
                                    <Typist
                                        cursor={
                                            {
                                                show: true,
                                                blink: true,
                                                element: `|`,
                                                hideWhenDone: true,
                                                hideWhenDoneDelay: 0,
                                            }
                                        }
                                        avgTypingDelay={100}
                                    ><span>
                          Kompaniyangiz kelajagini biz bilan quring
                        </span></Typist>
                                </span> : <span>
                                Build your future with us
                                </span>

                        }
                    </Title>
                    {/*</Title>*/}
                    {/*<Title>*/}
                    {/*    <Typist*/}
                    {/*        cursor={*/}
                    {/*            {*/}
                    {/*                show: true,*/}
                    {/*                blink: true,*/}
                    {/*                element: `|`,*/}
                    {/*                hideWhenDone: true,*/}
                    {/*                hideWhenDoneDelay: 0,*/}
                    {/*            }*/}
                    {/*        }*/}
                    {/*        avgTypingDelay={100}*/}
                    {/*    ><span>*/}
                    {/*    {t("buildTitle")}*/}
                    {/*    </span></Typist>*/}
                    {/*</Title>*/}
                    <Text className="text-white mt-4">{t("buildText")}</Text>
                    <a href="#services" onClick={() => setScroll(false)}>
                        <LearnMore>
                            <div className="d-flex align-items-center">
                                <div style={{transform: "rotate(90deg)"}}>
                                    <ArrowBottom chevronBottom={chevronBottom}></ArrowBottom>
                                </div>
                                <div className="mouse d-flex align-items-center justify-content-end mx-3">
                                    <div className="mouse-line"></div>
                                </div>
                                <p className="mb-0">{t("learn more")}</p>
                            </div>
                        </LearnMore>
                    </a>
                </Col>
                <Col md="6" sm="12">
                    <img src={pencil} className="w-100" alt="pencilListImage"/>
                </Col>
            </Row>
        </BuildSection>
    );
};

export default PencilAnimation;