// React Imports
import React, {useContext} from 'react';
import { Link } from "react-router-dom";

// Images
import Logo from "../../../../assets/images/logo.png"

// Icons
import phone from "../../../../assets/icons/phone.svg"
import burger from "../../../../assets/icons/burger.svg"
import close from "../../../../assets/icons/fi_close.svg"
import arrowBottom from "../../../../assets/icons/arrow-bottom.svg"
import uz from "../../../../assets/icons/uz.png"
import eng from "../../../../assets/icons/eng.svg"
import {useTranslation} from "react-i18next"

// Styles
import {
    Navigationbar,
    Phone
} from "./style"
import {SystemContext} from "../../../../routes";

const Navbar = (props: any) => {
    const {setLanguage}=useContext<any>(SystemContext);
    const { t, i18n } = useTranslation();

    return (
        <Navigationbar>
            <nav id="home" className="navbar navbar-expand-lg justify-content-between">
                <Link to="/" className="navbar-brand">
                    <img src={Logo} className="w-100" alt="Logotip Unical"/>
                </Link>
                <div className="medianav d-flex align-items-center justify-content-between">
                    <div className="close"><div className="d-flex justify-content-end"><img src={close} alt="Close"/></div></div>
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a href="#home" className="nav-link">{t("home")}</a>
                        </li>
                        <li className="nav-item">
                            <a href="#services" className="nav-link">{t("services")}</a>
                        </li>
                        <li className="nav-item">
                            <a href="#comfortOil" className="nav-link">{t("portfolio")}</a>
                        </li>
                        <li className="nav-item">
                            <a href="#clients" className="nav-link">{t("clients")}</a>
                        </li>
                        <li className="nav-item">
                            <a href="#contact" className="nav-link">{t("contact")}</a>
                        </li>
                        <li className="nav-item">
                            <div className="nav-link">
                                <div className="d-flex align-items-center">
                                    <p className="mb-0 me-2">{t("language")}</p>
                                    <img src={arrowBottom} alt="Arrow-bottom"/>
                                </div>
                                <div className="language">
                                    <div className="item d-flex align-items-center" onClick={() => {
                                        i18n.changeLanguage('uz')
                                        localStorage.setItem("lang", "uz")
                                        setLanguage('uz')
                                    }}>
                                        <img src={uz} alt="uzb"/>
                                        <p className="mb-0 ms-2">Uz</p>
                                    </div>
                                    <div className="item d-flex align-items-center" onClick={() => {
                                        i18n.changeLanguage( 'en')
                                        localStorage.setItem("lang", "en")
                                        setLanguage('en');

                                    }}>
                                        <img src={eng} alt="uzb"/>
                                        <p className="mb-0 ms-2">En</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>

                </div>
                <div className="d-flex align-items-center">
                    <div className="phone-number">
                        <a href="tel:+998971993700" className="d-flex align-items-center text-decoration-none">
                            <Phone phone={phone} back={"#fff"}/>
                            <p className="mb-0 mx-1" style={{color: "rgba(255, 255, 255, 0.8)"}}>+998 97 199-37-00</p>
                        </a>
                    </div>
                </div>
                <span className="burger" onClick={()=> props.setClick(true)}>
                    <img src={burger} alt="Burger"/>
                </span>
            </nav>
        </Navigationbar>
    );
};

export default Navbar;