// React Imports
import React from 'react';
import {
    Card,
    CardBody,
} from "reactstrap";
import Marquee from "react-fast-marquee/dist";
import {useTranslation} from "react-i18next";


// Styles
import {
    OurClients
} from "./style";

// Images
import regBooks from "../../../../assets/images/regbooks.png"
import regPrint from "../../../../assets/images/Regprint.png"
import registonLc from "../../../../assets/images/REGISTON.png"
import itSchool from "../../../../assets/images/itSchool.png"
import comfortOil from "../../../../assets/images/comfortOil.png"
import bigant from "../../../../assets/images/bigantLogo.png"
import bestTen from "../../../../assets/images/bestten.png"
import millnium from "../../../../assets/icons/millenium.svg"

const Clients = () => {
    const {t} = useTranslation();
    return (
        <OurClients id="clients" className="mt-5 pt-5">
            <h3>{t("our clients")}</h3>
            <Marquee
                className="justify-content-between w-100"
                direction="right"
                speed={100}
                // pauseOnHover={true}
            >
                <Card>
                    <CardBody className="d-flex align-items-center justify-content-center">
                        <div>
                            <img src={regBooks} className="w-100" alt="Google"/>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody className="d-flex align-items-center justify-content-center">
                        <div>
                            <img src={comfortOil} className="w-100" alt="Amazon"/>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody className="d-flex align-items-center justify-content-center">
                        <div>
                            <img src={regPrint} className="w-100" alt="Microsoft"/>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody className="d-flex align-items-center justify-content-center">
                        <div>
                            <img src={bigant} className="w-100" alt="Uber"/>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody className="d-flex align-items-center justify-content-center">
                        <div>
                            <img src={registonLc} className="w-100" alt="Uber"/>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody className="d-flex align-items-center justify-content-center">
                        <div>
                            <img src={millnium} className="w-100" alt="Uber"/>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody className="d-flex align-items-center justify-content-center">
                        <div>
                            <img src={itSchool} className="w-100" alt="Uber"/>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody className="d-flex align-items-center justify-content-center">
                        <div>
                            <img src={bestTen} className="w-100" alt="Uber"/>
                        </div>
                    </CardBody>
                </Card>
            </Marquee>
            <Marquee
                className="justify-content-between"
                direction="left"
                speed={100}
                // pauseOnHover={true}
            >
                <Card>
                    <CardBody className="d-flex align-items-center justify-content-center">
                        <div>
                            <img src={regBooks} className="w-100" alt="Google"/>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody className="d-flex align-items-center justify-content-center">
                        <div>
                            <img src={comfortOil} className="w-100" alt="Amazon"/>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody className="d-flex align-items-center justify-content-center">
                        <div>
                            <img src={regPrint} className="w-100" alt="Microsoft"/>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody className="d-flex align-items-center justify-content-center">
                        <div>
                            <img src={bigant} className="w-100" alt="Uber"/>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody className="d-flex align-items-center justify-content-center">
                        <div>
                            <img src={registonLc} className="w-100" alt="Uber"/>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody className="d-flex align-items-center justify-content-center">
                        <div>
                            <img src={millnium} className="w-100" alt="Uber"/>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody className="d-flex align-items-center justify-content-center">
                        <div>
                            <img src={itSchool} className="w-100" alt="Uber"/>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody className="d-flex align-items-center justify-content-center">
                        <div>
                            <img src={bestTen} className="w-100" alt="Uber"/>
                        </div>
                    </CardBody>
                </Card>
            </Marquee>
        </OurClients>
    );
};

export default Clients;