// React Imports
import React, {useContext, useEffect, useState} from 'react';
import AOS from "aos"
import {Loader, Section} from "./style";
// Components
import Lottie from 'react-lottie';
import LoaderData from "./../../../assets/json/loading.json"
import Navbar from "../Components/navbar/navbar";
import PencilAnimation from "../Components/pencilAnimation/pencilAnimation";
import Services from "../Components/services/services";
import Comfortoil from "../Components/comfortOil/comfortoil";
import Bigant from "../Components/bigant/bigant";
import Clients from "../Components/clients/clients";
import ContactUs from "../Components/contact/contact";
import Footer from "../Components/footer/footer";
// Styles
import "animate.css";
import "aos/dist/aos.css"
import axios from "axios";
import {Modal} from "reactstrap";
import close from "../../../assets/icons/fi_close.svg";
import {Phone} from "../Components/navbar/style";
import phone from "../../../assets/icons/phone.svg";
import arrowBottom from "../../../assets/icons/arrow-bottom.svg";
import uz from "../../../assets/icons/uz.png";
import eng from "../../../assets/icons/eng.svg";
import {useTranslation} from "react-i18next";
import {SystemContext} from "../../../routes";
import LoadingData from "../../../assets/json/loading.json";

AOS.init();

const Container = () => {

    const {t, i18n} = useTranslation();
    const {setLanguage}=useContext<any>(SystemContext);


    // States
    const [nameInput, setNameInput] = useState("");
    const [numberInput, setNumberInput] = useState("");
    const [messageInput, setMessageInput] = useState("");
    const [modal, setModal] = useState(false);
    const [click, setClick] = useState<boolean>(false)
    const [loading, setLoading] = useState(true)

    // Functions
    const handleClick = (e: any, value: string) => {
        e.preventDefault();
        // @ts-ignore
        const url = `${process.env.REACT_APP_API_ROOT}sendMessage?chat_id=-1001658024258&text=Name : ${nameInput}\n\nNumber: ${numberInput}\n\nMessage: ${messageInput}`;
        axios.get(encodeURI(url)).then(() => {
            setNameInput("");
            setNumberInput("");
            setMessageInput("");
            setModal(true);
            setTimeout(() => setModal(false), 2000)
        }).catch((e) => {
        })
    };

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }, [])

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: LoaderData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <>
            {
                loading ? <Loader className="vh-100 h-100">
                    <Lottie
                        options={defaultOptions}
                        height={500}
                        width={500}
                    />
                </Loader> : <Section click={click} className={`${loading ? "" : "sectionAnimation"}`} >
                    <div>
                        <Modal
                            isOpen={modal}
                            toggle={() => setModal(!modal)}
                            className="bg-transparent border-0"
                        >
                            {/*<ModalBody className="bg-transparent">*/}
                            <div className="alert alert-success m-0 text-center" role="alert">
                                Xabaringiz muvoffaqiyatli yuborildi!
                            </div>
                            {/*</ModalBody>*/}
                        </Modal>
                        <div className="medianavs d-flex align-items-center justify-content-between" style={{overflowY: "hidden"}}>
                            <div className="close" onClick={()=>setClick(false)}>
                                <div className="d-flex justify-content-end"><img src={close} alt="Close"/></div>
                            </div>
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a href="#home" onClick={()=>setClick(false)}className="nav-link">{t("home")}</a>
                                </li>
                                <li className="nav-item">
                                    <a href="#services" onClick={()=>setClick(false)} className="nav-link">{t("services")}</a>
                                </li>
                                <li className="nav-item">
                                    <a href="#comfortOil" onClick={()=>setClick(false)} className="nav-link">{t("portfolio")}</a>
                                </li>
                                <li className="nav-item">
                                    <a href="#clients" onClick={()=>setClick(false)} className="nav-link">{t("clients")}</a>
                                </li>
                                <li className="nav-item">
                                    <a href="#contact" onClick={()=>setClick(false)} className="nav-link">{t("contact")}</a>
                                </li>
                                <li className="nav-item">
                                    <div className="nav-link">
                                        <div className="d-flex align-items-center">
                                            <p className="mb-0 me-2">{t("language")}</p>
                                            <img src={arrowBottom} alt="Arrow-bottom"/>
                                        </div>
                                        <div className="language">
                                            <div className="item d-flex align-items-center" onClick={() => {
                                                i18n.changeLanguage('uz')
                                                localStorage.setItem("lang", "uz")
                                                setLanguage('uz')
                                            }}>
                                                <img src={uz} alt="uzb"/>
                                                <p className="mb-0 ms-2">Uz</p>
                                            </div>
                                            <div className="item d-flex align-items-center" onClick={() => {
                                                i18n.changeLanguage( 'en')
                                                localStorage.setItem("lang", "en")
                                                setLanguage('en');
                                            }}>
                                                <img src={eng} alt="uzb"/>
                                                <p className="mb-0 ms-2">En</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div className="phone-number">
                                <a href="tel:+998971993700" className="d-flex align-items-center text-decoration-none">
                                    <Phone phone={phone} back={"#fff"}/>
                                    <p className="mb-0 mx-1" style={{color: "rgba(255, 255, 255, 0.8)"}}>+998 97 199-37-00</p>
                                </a>
                            </div>
                        </div>
                        <Navbar setClick={setClick}/>
                        <PencilAnimation/>
                        <Services/>
                        <Comfortoil/>
                        <Bigant/>
                        <Clients/>
                        <ContactUs
                            nameInput={nameInput}
                            numberInput={numberInput}
                            messageInput={messageInput}
                            setNameInput={setNameInput}
                            setNumberInput={setNumberInput}
                            setMessageInput={setMessageInput}
                            handleClick={handleClick}
                        />
                        <Footer/>
                    </div>
                </Section>
            }

        </>
    );
};

export default Container;