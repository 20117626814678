// React Imports
import React from 'react';
import {
    Card,
    CardBody
} from "reactstrap";
import {AnimationOnScroll} from "react-animation-on-scroll";

// Types
interface Type {
    img: any,
    backStyle: any,
    text: string,
    animation: any
}

const ServicesMiniCard = (props: Type) => {
    return (
        <AnimationOnScroll
            animateIn={props.animation.animateIn}
            duration={props.animation.duration}
            delay={props.animation.delay}
            animateOnce={props.animation.animateOnce}
        >
            <Card style={{height: "140px",  borderRadius: "10px"}}>
                <CardBody className="d-flex align-items-center justify-content-center">
                    <div>
                        <props.backStyle className="d-flex align-items-center justify-content-center mx-auto mb-2">
                            <img src={props.img} alt="Web site"/>
                        </props.backStyle>
                        <span className="text-white d-flex justify-content-center">{props.text}</span>
                    </div>
                </CardBody>
            </Card>
        </AnimationOnScroll>
    );
};

export default ServicesMiniCard;