export const en = {
    "language": "Language",
    "home": "Home",
    "services": "Services",
    "portfolio": "Portfolio",
    "clients": "Clients",
    "contact": "Contact",
    "build title": "Build your future with us",
    "build text": "Design and development of websites, mobile applications, online stores and complex Internet projects.",
    "learn more": "Learn more",
    "our services": "Our services",
    "services card one title": "Development of landing pages",
    "services card one text": "The landing page is formed in such a way as to briefly and clearly state the essence of the proposal, arouse interest, and convince the client that this is what he wants.",
    "services card two title": "IT StartUps",
    "services card two text": "A corporate site is a site of a company, its official virtual representation on the Internet.",
    "services card three title": "Development of landing pages",
    "services card three text": "The landing page is formed in such a way as to briefly and clearly state the essence of the proposal, arouse interest, and convince the client that this is what he wants.",
    "services card four title": "IT consulting",
    "services card four text": "Online trade is gaining momentum in Uzbekistan, we create online stores on ready-made engines, as well as unique stores, we connect national and foreign payment methods.",
    "services card five title": "Development of mobile applications",
    "services card five text": "Slate helps you see how many more days you need to work to reach your financial goal.",
    "services card six title": "Implementation of ERP systems",
    "services card six text": "Slate helps you see how many more days you need to work to reach your financial goal.",
    "comfort oil": "Comfort oil",
    "comfort oil text": "O’zbekiston bozorida birinchi va yagona yoqilg’i yetkazib berish xizmati ",
    "what we did": "What we did",
    "web site": "Web sayt",
    "admin panel": "Admin panel",
    "ios": "Ios",
    "android": "Android",
    "web design": "Web Design",
    "mobil design": "Mobil design",
    "bot": "Bot",
    "our clients": "Our clients",
    "contact us": "Contact Us",
    "your name": "Your Name",
    "your number": "Your Number",
    "your message": "Your Message",
    "send": "Send",
    "social media": "Social media",
}