import styled from "styled-components";
import {Container} from "reactstrap";

export const Comfort = styled(Container)`
margin-top: 150px;
position: relative;
@media (max-width: 576px){
margin-top: 0;
}
.comfort-unicalU{
position: absolute;
width: 84px;
height: 100px;
top: -60px;
left: 12%;
transform: rotate(50deg);
@media (max-width: 576px){
   width: 250px;
   height: 300px;
   left: 20%;
   transform: rotate(70deg);
}
@media (max-width: 390px){
  width: 200px;
  height: 250px;
}
}
.comfort-unicalU2{
position: absolute;
width: 100px;
height: 120px;
top: 100px;
right: 0;
transform: rotate(30deg);
@media (max-width: 1200px){
  right: 5%;
}
@media (max-width: 576px){
      display: none;
    }
     @media (min-width: 576px){
      display: block;
    }
}
.row{
  .col-md-6:first-child{
    @media (max-width: 1200px){
      display: block;
      min-width: 60%;
    }
     @media (min-width: 1200px){
      display: none;
    }
  }
   .col-md-6:nth-child(2){
      padding: 0 16px !important;
      @media (max-width: 1200px){
        min-width: 70%;
        margin-left: auto;
        margin-right: auto;
      }
      @media (max-width: 992px){
        min-width: 90% !important;
      }
   }
  .col-md-6:last-child{
  @media (max-width: 1200px){
    min-width: 100%;
  }
  h1, p{
   @media (max-width: 1200px){
      display: none;
    }
     @media (min-width: 1200px){
      display: block;
    }
  }
   .row{
        @media (max-width: 1200px){
          transform: translateY(-50px);
        }
        @media (max-width: 1200px){
          margin-left: auto;
          margin-right: auto;
        }
        
        .col-md-4{
          max-width: 33% !important;
          @media (max-width: 1200px){
            max-width: 33% !important;
        }
        }
        .card{
        transition: all 0.3s ease-in-out;
        &:hover{
          transform: translateY(-15px);
          box-shadow: 0 0 20px #23265C;
         } 
         @media (max-width: 576px){
            height: 100px !important;
            .card-body{
              padding: 8px 0 !important;
            }
            span{
              font-size: 12px !important;
            }
          }
        @media (max-width: 767px) and (min-width: 576px){
        height: 100px !important;
          span{
            font-size: 14px !important;
          }
        }
          @media (max-width: 420px){
            span{
              font-size: 10px !important;
            }
          }
          @media (max-width: 1400px) and (min-width: 1200px){
            .card-body{
              padding: 10px;
              span{
                font-size: 13px !important;
              }
            }
          }
          span{
            font-size: 15px;
          }
        }
      }
  }
  .col-md-10{
    @media (max-width: 1200px){
      min-width: 100%;
    }
  }
}
  h1{
    font-weight: bold;
    font-size: 64px;
    line-height: 55px;
    color: #EC8C00;
    @media (max-width: 576px){
      font-size: 45px;
    }
  }
  p{
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
  }
  h3{
    font-weight: 500;
    font-size: 32px;
    line-height: 55px;
  }
  span{
    font-weight: 500;
    font-size: 18px;
    opacity: 0.8;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .card{
    background-color: #23265C;
    height: 100%;
    cursor: pointer;
  }
`;

export const IPhoneLeft = styled.div`
  transform: translateX(45px);
  @media (max-width: 576px){
    //transform: translateX(0);
  }
`;
export const IPhoneMiddle = styled.div`
  z-index: 999999 !important;
  @media (max-width: 576px){
    //transform: translateX(-45px);
  }
`;
export const IPhoneRight = styled.div`
  transform: translateX(-45px);
  @media (max-width: 576px){
    //transform: translateX(-80px);
  }
`;
export const Radial = styled.div`
  background: radial-gradient(50% 50% at 50% 50%, rgba(196, 196, 196, 0.5) 0%, rgba(196, 196, 196, 0) 100%);
  height: 200px;
  transform: translateY(-120px);
  @media (max-width: 576px){
    //height: 100px;
  }
`;
export const WebSite = styled.div`
    width: 48px;
    height: 48px;
    background: linear-gradient(154.24deg, #2091F9 4.45%, #0085FF 83.73%);
    background-blend-mode: luminosity;
    mix-blend-mode: normal;
    box-shadow: 1px 5px 30px rgba(32, 145, 249, 0.5);
    border-radius: 10px;
    @media (max-width: 576px){
      width: 40px;
      height: 40px;
    }
`;
export const Admin = styled.div`
    width: 48px;
    height: 48px;
    background: linear-gradient(141.58deg, #FF9A9A 3.63%, #F14C4C 91.45%);
    mix-blend-mode: normal;
    box-shadow: 1px 5px 30px rgba(215, 21, 21, 0.6);
    border-radius: 10px;
    @media (max-width: 576px){
      width: 40px;
      height: 40px;
    }
`;
export const Apple = styled.div`
    width: 48px;
    height: 48px;
    background: linear-gradient(143.75deg, #4EDAAC 0%, #4EDAAC 88.72%);
    background-blend-mode: luminosity;
    mix-blend-mode: normal;
    box-shadow: 1px 5px 30px rgba(78, 218, 172, 0.5);
    border-radius: 10px;
    @media (max-width: 576px){
      width: 40px;
      height: 40px;
    }
`;
export const Android = styled.div`
    width: 48px;
    height: 48px;
    background: linear-gradient(143.75deg, #FF8A51 0%, #F0580F 88.72%);
    background-blend-mode: luminosity;
    mix-blend-mode: normal;
    box-shadow: 1px 5px 30px rgba(241, 76, 76, 0.6);
    border-radius: 10px;
    @media (max-width: 576px){
      width: 40px;
      height: 40px;
    }
`;
export const WebDesign = styled.div`
    width: 48px;
    height: 48px;
    background: linear-gradient(143.75deg, #FE9AF4 0%, #EC4EDC 88.72%);
    background-blend-mode: luminosity;
    mix-blend-mode: normal;
    box-shadow: 1px 5px 30px rgba(230, 68, 214, 0.5);
    border-radius: 10px;
    @media (max-width: 576px){
      width: 40px;
      height: 40px;
    }
`;
export const MobileDesign = styled.div`
    width: 48px;
    height: 48px;
    background: linear-gradient(163.65deg, #FCD876 4.69%, #FABC13 88.66%);
    background-blend-mode: luminosity;
    mix-blend-mode: normal;
    box-shadow: 1px 5px 30px rgba(238, 185, 42, 0.6);
    border-radius: 10px;
    @media (max-width: 576px){
      width: 40px;
      height: 40px;
    }
`;