// React Imports
import React from 'react';
import {
    Card, CardBody,
    Col,
    Row
} from "reactstrap";
import {
    Map,
    YMaps,
    Placemark,
    ZoomControl,
    FullscreenControl,
    SearchControl,
    TrafficControl,
    GeolocationControl
} from "react-yandex-maps";


// Components
import Form from "../../../../components/contactUs/form"

// Styles
import {
    ContactUs,
    TextContact
} from "./style";

// Images
import maps from "../../../../assets/icons/fi_map.svg"
import phone from "../../../../assets/icons/fi_phone-android.svg"
import email from "../../../../assets/icons/fi_mail.svg"
import mapsIcon from "../../../../assets/icons/location.png"

//Types
import { ContactTypes } from "../../Types/types";
import {useTranslation} from "react-i18next";

const Contact = (props: ContactTypes) => {
    const {t} = useTranslation();
    return (
        <ContactUs id="contact" className="mt-5 pt-5">
            <h3>{t("contact us")}</h3>
            <Row className="pt-5">
                <Col sm="12" style={{cursor: "pointer"}} onClick={() => {window.open("https://maps.google.com?q="+41.355537330890826+","+69.28798409762611)}}>
                    <div className="d-flex justify-content-center">
                        <img src={maps} width="25" height="31" alt="Maps"/>
                    </div>
                    <TextContact className="text-center text-white">Toshkent, Amir Temur ko'chasi 129B</TextContact>
                </Col>
                <Col sm="12">
                    <a href="tel:+999781993700" className="text-decoration-none" style={{cursor: "pointer"}}>
                        <div className="d-flex justify-content-center">
                            <img src={phone} width="25" height="31" alt="Maps"/>
                        </div>
                        <TextContact className="text-center text-white">(97) 199-3700</TextContact>
                    </a>
                </Col>
            </Row>
            <Row className="pt-5">
                <Col md="4" sm="12">
                    <Form
                        nameInput={props.nameInput}
                        numberInput={props.numberInput}
                        messageInput={props.messageInput}
                        setNameInput={props.setNameInput}
                        setNumberInput={props.setNumberInput}
                        setMessageInput={props.setMessageInput}
                        handleClick={props.handleClick}
                    />
                </Col>
                <Col md="8" sm="12">
                    <Card className="bg-transparent border-0">
                        <CardBody>
                            <Row>
                                <Col md="4" sm="12" style={{cursor: "pointer"}} onClick={() => {window.open("https://maps.google.com?q="+41.355537330890826+","+69.28798409762611)}}>
                                    <img src={maps} width="25" height="31" className="mx-auto d-block" alt="Maps"/>
                                    <TextContact className="text-center text-white">Toshkent, Amir Temur ko'chasi 129B</TextContact>
                                </Col>
                                <Col md="4" sm="12">
                                    <a href="tel:+999781993700" style={{cursor: "pointer", textDecoration: "none"}}>
                                        <img src={phone} width="25" height="31" className="mx-auto d-block" alt="Maps"/>
                                        <TextContact className="text-center text-white">(97) 199-3700</TextContact>
                                    </a>
                                </Col>
                                <Col md="4" sm="12">
                                    <a href="mailto:unicalsolutions@gmail.com" className="text-decoration-none">
                                        <img src={email} width="25" height="31" className="mx-auto d-block" alt="Maps"/>
                                        <TextContact className="text-center text-white">unicalsolutions@gmail.com</TextContact>
                                    </a>
                                </Col>
                            </Row>
                            <YMaps>
                                <Map
                                    defaultState={{ center: [41.355537330890826, 69.28798409762611], zoom: 17 }}
                                    // width={}
                                    width={"100%"}
                                    height={window.innerWidth > 992 && window.innerWidth < 1200 ? "400px" : "480px"}

                                >
                                    <Placemark
                                        options={{
                                            iconLayout: "default#image",
                                            iconImageSize: [37, 37],
                                            iconImageHref: mapsIcon,
                                            iconImageOffset: [-5, -38],
                                        }}
                                        geometry={[41.355541303825134, 69.28797071738357]} />
                                    <ZoomControl options={{ float: 'right' }} />
                                    <FullscreenControl />
                                    <SearchControl options={{ float: 'left' }} />
                                    <TrafficControl options={{ float: 'right' }} />
                                    <GeolocationControl options={{ float: 'left' }} />
                                </Map>
                            </YMaps>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </ContactUs>
    );
};

export default Contact;