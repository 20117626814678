export const uz = {
    "language": "Til",
    "home": "Bosh sahifa",
    "services": "Xizmatlar",
    "portfolio": "Portfolio",
    "clients": "Mijozlar",
    "contact": "Aloqa",
    "build title": "Kelajagingizni biz bilan quring",
    "build text": "Veb-saytlar, mobil ilovalar, onlayn-do'konlar va murakkab Internet loyihalarini loyihalash va ishlab chiqish",
    "learn more": "Learn more",
    "our services": "Bizning xizmatlar",
    "services card one title": "Landing page",
    "services card one text": "Landing page kompaniyaning mohiyatini qisqacha va aniq bayon qilgan holda, qiziqish uyg'otadigan va mijozni u xohlagan narsaga ishontiradigan tarzda tuziladi",
    "services card two title": "Korporativ saytlar",
    "services card two text": "Korporativ veb saytlardan tortib veb ilovalargacha boʻlgan murakkablikdagi internet saytlarni sifat kafolati bilan ishlab chiqamiz",
    "services card three title": "Telegram bot",
    "services card three text": "Telegram botlar mijozlaringiz bilan aloqa qilishga yordam beradi. Telegram botlar har qanday biznes boshlash uchun eng yaxshi tanlov",
    "services card four title": "Mobil ilova",
    "services card four text": "Istalgan qiyinchilikdagi mobil ilovalarni ishlab chiqish va ularni texnik qoʻllab-quvvatlash",
    "services card five title": "It startuplar",
    "services card five text": "G’oyani amaliyotga tadbiq etamiz",
    "services card six title": "ERP & CRM",
    "services card six text": "Biznesni va jarayonlarni avtomatlashtirish, kanselyariya ishlarini 100% gacha kamaytiruvchi boshqaruv elektron tizimlarini ishlab chiqish va amaliyotga tadbiq etish",
    "comfort oil": "Comfort oil",
    "comfort oil text": "O’zbekiston bozorida birinchi va yagona yoqilg’i yetkazib berish xizmati ",
    "what we did": "Biz nima qildik",
    "web site": "Web sayt",
    "admin panel": "Admin panel",
    "ios": "Ios",
    "android": "Android",
    "web design": "Web dizayn",
    "mobil design": "Mobil dizayn",
    "bot": "Bot",
    "our clients": "Bizning mijozlar",
    "contact us": "Aloqa",
    "your name": "Ismingiz",
    "your number": "Telefon raqamingiz",
    "your message": "Xabar",
    "send": "Jo'natish",
    "social media": "Ijtimoiy tarmoqlar",





}