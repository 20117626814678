import styled from "styled-components";
import {Container} from "reactstrap";

export const Service = styled(Container)`
  padding-top: 250px;
  position: relative;
  width: 100%;
  @media (max-width: 576px){
    padding-top: 40px;
  }
   @media (max-width: 992px){
        padding-top: 50px;
   }
  .service-unicalU{
    position: absolute;
    top: 100px;
    left: 20%;
    @media (max-width: 576px){
    top: -200px;
    left: 0;
    transform: rotate(14deg);
    width: 275px;
    height: 358px;
  }
   @media (max-width: 992px){
        top: -250px;
      }
  }
  .service-unicalU2{
    position: absolute;
    width: 50px;
    height: 60px;
    transform: rotate(70deg);
    right: 0;
    @media (max-width: 1200px){
    display: none !important;
  }
  
  @media (min-width: 1200px){
    display: block !important;
  }
  }
  h1{
    font-weight: 500;
    font-size: 48px;
    line-height: 55px;
    color: #2091F9;
    @media (max-width: 576px){
      font-size: 40px !important;
    }
  }
  h3{
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #fff;
    @media (max-width: 576px){
    text-align: center;
    margin-top: 15px;
  }
  @media (max-width: 992px){
    margin-top: 20px;
  }
  }
  p{
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      font-weight: normal !important;
      color: #e1e1e1 !important;
      
      @media (max-width: 576px){
        text-align: center !important;
      }
      @media (max-width: 992px){
        text-align: start;
      }
  }
  .row{
   @media (max-width: 576px){
      padding-top: 0px !important;
   }
    .card{
      background: #23265C; 
      border: 1px solid rgba(0, 0, 0, 0.05);
      box-sizing: border-box;
      box-shadow: -4px -4px 4px rgba(37, 43, 66, 0.06), 4px 4px 4px rgba(37, 43, 66, 0.06);
      border-radius: 8px;
      cursor: pointer;
      height: 100%;
      transition: all 0.3s ease-in-out;
     
     &:hover{
      transform: translateY(-20px);
      box-shadow: 0 0 20px #23265C;
     } 
      .card-body{
     
      img{
        height: auto;
        @media (max-width: 992px) and (min-width: 767px){
          height: 250px;
        }
      }
        .row{
          .col-md-6:first-child{
            @media (max-width: 992px){
              display: block;
              min-width: 100%;
            }
            @media (min-width: 992px){
              display: none;
            }
          }
          .col-md-6:nth-child(2){
            @media (max-width: 992px){
              min-width: 100%;
              display: block !important;
            }
           
          }
          .col-md-6:last-child{
            @media (max-width: 992px){
              display: none;
            }
            @media (min-width: 992px){
              display: block;
            }
          }
        }
      }
      
    }
  }
`