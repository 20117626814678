// React Imports
import React, {createContext, FC, Suspense, useEffect, useState} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';

// Routes
import { routes } from "./routes.path";
export const SystemContext=createContext<any>(null);



const Routes: FC<{}> = () => {
    const [language, setLanguage]=useState<any>("en");
    const [scroll, setScroll] = useState(true)
    // const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLanguage(window.localStorage.getItem("lang"))
        // setLoading(true)
    }, []);
    return <SystemContext.Provider value={{language, setLanguage, setScroll, scroll}}>
        <Router>
            <Suspense fallback={<div></div>}>
                {
                    routes.map((route, index) => {
                        return <Route key={index} exact {...route}/>
                    })
                }
            </Suspense>
        </Router>
    </SystemContext.Provider>
};

export default Routes;